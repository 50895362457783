<template>
  <div v-if="permission.access">
    <div class="w-100 text-right">
      <b-dropdown
        v-if="permission.delete"
        text="Action"
        variant="outline-primary"
        class="mb-2"
        :disabled="!select.selected"
      >
        <b-dropdown-item @click="Delete"> Delete </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="4">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <v-select
                  id="category_id"
                  v-model="search.category_id"
                  placeholder="Main Category"
                  :options="main_categories"
                  label="name"
                  :reduce="(category) => category.id"
                >
                  <template #category="{ name }">
                    <span>{{ name }}</span>
                  </template>
                </v-select>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th class="text-center">Icon</th>
                  <th>Main Category</th>
                  <th>Name</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                  <th class="align-middle" v-if="permission.delete">
                    <label
                      class="custom-control custom-checkbox mb-0 d-inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="checkAllDataTables"
                        v-model="selectedAll"
                        @click="selectAll"
                      />
                      <span class="custom-control-label">&nbsp;</span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(category, index) in categories.data"
                  :key="index"
                  v-if="categories.data.length"
                >
                  <td class="align-middle text-center">
                    {{ categories.from + index }}
                  </td>
                  <td class="align-middle text-center">
                    <img
                      width="60"
                      :src="
                        category.icon
                          ? $store.state.base_url + category.icon
                          : $store.state.base_url +
                            'images/category/default.png'
                      "
                      alt=""
                    />
                  </td>
                  <td class="align-middle">
                    <span
                      v-if="category.main_category"
                      class="badge badge-success"
                    >
                      <b>{{ category.main_category.name }}</b>
                    </span>
                    <span v-else class="badge badge-primary">
                      Main Category
                    </span>
                  </td>

                  <td>
                    <p class="mb-0"><b>Name : </b>{{ category.name }}</p>
                    <p class="mb-0"><b>Slug : </b>{{ category.slug }}</p>
                    <span
                      class="badge mr-1"
                      :class="
                        category.status == 1 ? 'badge-success' : 'badge-danger'
                      "
                    >
                      {{ category.status == 1 ? "Active" : "Inactive" }}
                    </span>
                    <span
                      class="badge"
                      :class="
                        category.is_show == 1
                          ? 'badge-primary'
                          : 'badge-warning'
                      "
                    >
                      {{ category.is_show == 1 ? "Show" : "Hidden" }}
                    </span>
                  </td>
                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(category.id)"
                    >
                      <feather-icon icon="EditIcon" />
                    </button>
                  </td>
                  <td v-if="permission.delete" class="align-middle">
                    <b-form-checkbox
                      v-model="select.data"
                      :value="category.id"
                      name="flavour-3a"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ categories.from ? categories.from : 0 }} to
                  {{ categories.to ? categories.to : 0 }} of
                  {{ categories.total ? categories.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="categories"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Category'">
          <b-card-text>
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group
                      label="Select Main Category"
                      label-for="category_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Select Main Category"
                      >
                        <v-select
                          id="category_id"
                          v-model="form.category_id"
                          placeholder="Select Main Category"
                          :options="main_categories"
                          label="name"
                          :reduce="(category) => category.id"
                        >
                          <template #category="{ name }">
                            <span>{{ name }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Category Name" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="category name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          placeholder="Enter Your Category Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Category Slug" label-for="slug">
                      <validation-provider
                        #default="{ errors }"
                        name="category slug"
                        rules="required"
                      >
                        <b-form-input
                          id="slug"
                          v-model="form.slug"
                          :state="errors.length > 0 ? false : null"
                          name="slug"
                          placeholder="Enter Your Category Slug"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Description" label-for="description">
                      <validation-provider
                        #default="{ errors }"
                        name="description"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="form.description"
                          :state="errors.length > 0 ? false : null"
                          name="description"
                          placeholder="Enter Your Description"
                          rows="4"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Meta Title" label-for="meta_title">
                      <validation-provider
                        #default="{ errors }"
                        name="meta_title"
                      >
                        <b-form-input
                          id="meta_title"
                          v-model="form.meta_title"
                          :state="errors.length > 0 ? false : null"
                          name="meta_title"
                          placeholder="Enter Your Meta Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Meta Description"
                      label-for="meta_description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="meta_description"
                      >
                        <b-form-textarea
                          id="meta_description"
                          v-model="form.meta_description"
                          :state="errors.length > 0 ? false : null"
                          name="meta_description"
                          placeholder="Enter Your Meta Description"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="col-md-12" v-if="!form.category_id">
                    <div class="border rounded p-2 mb-2">
                      <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row align-items-center"
                      >
                        <b-media-aside>
                          <b-img
                            :src="icon"
                            height="50"
                            width="50"
                            class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                        <b-media-body>
                          <b-form-file
                            id="icon"
                            accept=".jpg, .png, .gif"
                            @change="loadImage($event)"
                            placeholder="Select Category Icon"
                            drop-placeholder="Drop file here..."
                          />
                        </b-media-body>
                      </b-media>
                    </div>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                  Status
                </b-form-checkbox>
                <b-form-checkbox
                  name="is_show"
                  style="margin-top: 5px"
                  v-model="form.is_show"
                  switch
                  inline
                >
                  Is Show
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      selected: [],
      search: {
        category_id: "",
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        category_id: "",
        slug: "",
        name: "",
        description: "",
        meta_title: "",
        meta_description: "",
        status: true,
        is_show: true,
        icon: "",
      },
      icon: this.$store.state.base_url + "images/category/default.png",
      categories: {},

      select: {
        employee_id: null,
        data: [],
        selected: false,
      },
      selectedAll: false,

      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
    this.$store.dispatch("getMainCategory");
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const categories = await this.callApi(
        "post",
        "/app/course/categories?page=" + this.search.page,
        this.search
      );
      if (categories.status == 200) {
        this.categories = categories.data.categories;
      }
    },

    Delete() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteAlert("/app/course/categories/delete", this.select);
          this.select.data = [];
          this.getResults();
        }
      });
    },

    loadImage(e) {
      this.form.icon = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.icon = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Close() {
      this.form.id = "";
      this.form.name = "";
      this.form.category_id = "";
      this.form.slug = "";
      this.form.meta_title = "";
      this.form.meta_description = "";
      this.form.description = "";
      this.form.status = true;
      this.form.is_show = true;
      this.form.icon = "";
      this.icon = this.$store.state.base_url + "images/category/default.png";
    },

    async edit(id) {
      const category = await this.callApi(
        "post",
        "/app/course/categories/edit/" + id
      );
      if (category.status == 200) {
        this.form.id = category.data.category.id;
        this.form.category_id = category.data.category.category_id;
        this.form.name = category.data.category.name;
        this.form.slug = category.data.category.slug;
        this.form.meta_title = category.data.category.meta_title;
        this.form.description = category.data.category.description;
        this.form.meta_description = category.data.category.meta_description;
        this.form.status = category.data.category.status == true ? true : false;
        this.form.is_show =
          category.data.category.is_show == true ? true : false;
        this.icon = this.$store.state.base_url + category.data.category.icon;
      }
    },

    add() {
      const fd = new FormData();
      if (this.form.icon) {
        fd.append("icon", this.form.icon, this.form.icon.name);
      }
      fd.append("id", this.form.id);

      fd.append("slug", this.form.slug);
      fd.append("description", this.form.description);
      if (this.form.category_id) {
        fd.append("category_id", this.form.category_id);
      }
      fd.append("name", this.form.name);
      fd.append("meta_title", this.form.meta_title);
      fd.append("meta_description", this.form.meta_description);
      fd.append("status", this.form.status);
      fd.append("is_show", this.form.is_show);

      axios
        .post("/app/course/categories/store", fd)
        .then((res) => {
          this.getResults();
          this.$store.dispatch("getMainCategory");
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.icon)
              this.e(e.response.data.errors.icon[0]);

            if (e.response.data.errors.category_id)
              this.e(e.response.data.errors.category_id[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.slug)
              this.e(e.response.data.errors.slug[0]);
            if (e.response.data.errors.meta_title)
              this.e(e.response.data.errors.meta_title[0]);
            if (e.response.data.errors.meta_description)
              this.e(e.response.data.errors.meta_description[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
            if (e.response.data.errors.is_show)
              this.e(e.response.data.errors.is_show[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.categories.data.forEach((category) => {
          this.select.data.push(category.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "category.access") {
          this.permission.access = true;
        }

        if (permission.slug == "category.create") {
          this.permission.create = true;
        }

        if (permission.slug == "category.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "category.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.category_id": function (val) {
      this.SearchData();
    },
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "select.data": function (selected) {
      this.select.selected = selected.length > 0;
      this.selectedAll = selected.length == this.categories.data.length;
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    main_categories() {
      return this.$store.getters.getMainCategory;
    },
  },
};
</script>

<style></style>
